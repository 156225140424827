import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import HomeBackgroundSection from "../components/sections/home-background-section";
import SteelRockHouse from "../components/images/steel-rock-house";
import PolyDown from "../components/images/poly-down";
import { Helmet } from "react-helmet";
import AccessoryLadder from "../components/images/accessory-ladder";
import AccessoryLock from "../components/images/accessory-lock";
import withLocation from "../components/shared/withLocation";
import CartContext from "../context/cart-context";

const IndexPage = ({ data, search }) => {
  const { setDiscountCode } = useContext(CartContext);

  useEffect(() => {
    const getDiscount = async () => {
      setDiscountCode(search.code);
    };
    if (search.code) {
      getDiscount();
    }
  }, [search, setDiscountCode]);

  const acfData = data.wpPage;

  return (
    <Layout>
      <Seo
        title={acfData.seo.title}
        keywords={[`window well covers`, `steel window covers`, `window cover`]}
        description="Keep your family safe with our custom window well covers. We offer polycarbonate and steel window well covers that keep the elements and debris out. Contact us to learn more!"
      />
      <Helmet
        bodyAttributes={{
          class: "home-page",
        }}
      />

      <HomeBackgroundSection
        className="home-background-section"
        title={acfData.pages.h1Title}
        subtitle={acfData.layout.homePage.subtitle}
        buttons={acfData.layout.homePage.bigButtons}
        eyebrow1={acfData.layout.homePage.eyebrow1}
        eyebrow2={acfData.layout.homePage.eyebrow2}
      />

      <div className="w-full px-6 pt-24 pb-8 md:pb-24 bg-grey-lightest">
        <div className="container max-w-xl mx-auto flex flex-wrap">
          <div className="w-full md:w-1/2">
            <Link
              to="/plastic-polycarbonate-window-well-covers/"
              className="mb-4 block"
            >
              <PolyDown className="w-full h-auto" />
            </Link>

            <Link to="/steel-window-well-covers/">
              <SteelRockHouse className="w-full h-auto" />
            </Link>
          </div>

          <div
            className="w-full md:w-1/2 p-2 md:px-6 text-grey-darker"
            dangerouslySetInnerHTML={{
              __html: acfData.layout.homePage.section1Body,
            }}
          />
        </div>
      </div>

      <div className="w-full px-6 py-24 bg-white">
        <div className="container max-w-xl mx-auto flex flex-wrap text-grey-darker">
          <div
            className="w-full md:w-1/2 p-2 md:px-6"
            dangerouslySetInnerHTML={{
              __html: acfData.layout.homePage.section2Body,
            }}
          />
          <div className="w-full md:w-1/2">
            <Link to="/window-well-ladders" className="block mb-4">
              <AccessoryLadder className="w-full h-auto" />
            </Link>
            <Link to="/window-well-locks/" className="block mb-4">
              <AccessoryLock className="w-full h-auto" />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withLocation(IndexPage);

export const pageQuery = graphql`
  query HomePageQuery {
    wpPage(slug: { eq: "home" }) {
      id
      title
      slug
      status
      featuredImage {
        node {
          title
          localFile {
            publicURL
          }
        }
      }
      pages {
        h1Title
      }
      layout {
        homePage {
          eyebrow1
          eyebrow2
          subtitle
          bigButtons {
            leftText
            rightText
            link
            title
            buttonImage {
              localFile {
                publicURL
              }
            }
          }
          section1Body
          section2Body
        }
      }
      seo {
        title
        metaDesc
        canonical
      }
    }
  }
`;
