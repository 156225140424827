import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import classNames from "classnames";

import BackgroundImage from "gatsby-background-image";

const HomeBackgroundSection = ({
  className,
  title,
  subtitle,
  buttons,
  eyebrow1,
  eyebrow2,
}) => (
  <StaticQuery
    query={graphql`
      {
        desktop: file(relativePath: { eq: "steel-rock-house.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <>
          <BackgroundImage
            Tag="section"
            className={className}
            fluid={imageData}
          >
            <div
              className={`pt-16 pb-4 shadow-xl flex flex-col items-center justify-center text-red`}
            >
              {eyebrow1 && (
                <p className="text-5xl font-bold font-cursive underline">
                  {eyebrow1}
                </p>
              )}
              {eyebrow2 && (
                <p className="text-white lg:text-2xl text-shadow-lg underline">
                  {eyebrow2}
                </p>
              )}
            </div>
            <div className="w-full pt-4 pb-16 lg:pt-4 relative z-0">
              <div className="container md:mb-16 mx-auto text-left relative">
                <div className="w-full md:w-3/5 mt-8 md:mt-0 md:mb-16 text-center mx-auto text-shadow-lg">
                  <h1 className="text-2xl md:text-4xl font-bold text-white mb-3 leading-tight">
                    {title}
                  </h1>
                  <p className="text-white lg:text-xl font-light">{subtitle}</p>
                </div>
              </div>
            </div>
          </BackgroundImage>
          <div className="grid px-2 py-4 gap-4 lg:grid-cols-4">
            {buttons.map((button, index) => {
              return (
                <div className="grid lg:gap-y-4" key={index}>
                  <Link
                    className={`grid grid-cols-10 place-items-center py-6 no-underline w-full max-w-[400px] mx-auto rounded-t-lg lg:rounded-lg leading-tight shadow-lg bg-white text-${process.env.THEME_COLOR_PRIMARY}-dark`}
                    to={button.link}
                  >
                    <div
                      className={classNames(
                        "px-4 text-lg leading-tight whitespace-nowrap col-span-3",
                        "xl:text-xl xl:col-span-3"
                      )}
                      dangerouslySetInnerHTML={{ __html: button.leftText }}
                    />
                    <div className="h-full border-r border-grey"></div>
                    <div
                      className={classNames(
                        "col-span-6",
                        "md:col-span-5",
                        "xl:col-span-6"
                      )}
                      dangerouslySetInnerHTML={{ __html: button.rightText }}
                    />
                  </Link>
                  <div className="overflow-clip rounded-b-lg lg:rounded-lg max-h-[250px] w-fit mx-auto">
                    <Link to={button.link}>
                      <img
                        src={button.buttonImage.localFile.publicURL}
                        alt={button.title}
                        className="w-full max-w-[400px] h-auto rounded-b-lg lg:rounded-lg"
                      />
                    </Link>
                  </div>
                </div>
              );
            })}
            <Link
              className={classNames(
                `bg-${process.env.THEME_COLOR_PRIMARY} text-${process.env.THEME_COLOR_PRIMARY}-lightest`,
                "w-fit text-sm px-4 py-3 no-underline rounded uppercase mx-auto",
                "lg:col-span-4"
              )}
              to={"/compare-window-well-covers/"}
            >
              Compare Window Well Covers
            </Link>
          </div>
        </>
      );
    }}
  />
);

export default HomeBackgroundSection;
